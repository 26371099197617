<template>
  <div>
    <!-- <Logodia></Logodia> -->
    <!-- <div class="zhanweiforbuyfromcart"></div> -->
    <div class="contentbox">
      <div class="mainbox">
        <div class="addressbox" @click="openAddressPop">
          <div v-if="!buyInfo.deliverId">
            <div class="inlineblock">
              <img class="jiaicon" src="../../assets/imgs/jia@3x.png" alt="" />
            </div>
            <div class="inlineblock marginleft13">添加收货地址</div>
          </div>
          <div v-if="buyInfo.deliverId" class="flexbox">
            <div>
              <div class="addressinfobox">
                <span>
                  {{ buyInfo.deliverProvince }}
                </span>
                <span>
                  {{ buyInfo.deliverCity }}
                </span>
                <span>
                  {{ buyInfo.deliverArea }}
                </span>
                {{ buyInfo.deliverAddress }}
              </div>
              <div class="addressnamebox">
                {{ buyInfo.deliverName }}
                <span class="marginleft20">{{ buyInfo.deliverTel }}</span>
              </div>
            </div>
            <div>
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
        <div class="goodstitle">
          <div class="titlelable">商品信息</div>
          <div class="titleval">共{{ buyInfo.num }} 件</div>
        </div>
        <div
          class="mainitembox"
          v-for="(item, index) in buyInfo.productList"
          :key="index"
        >
          <div>
            <img class="mainimg" :src="item.productMainImage" alt="" />
          </div>
          <div class="mainiteninfobox">
            <div class="flexboxtwo">
              <div class="infonamebox">{{ item.productName }}</div>
              <!-- <div class="infotitlebox">图案印花T恤</div> -->
              <div class="skuinfo">
                <div
                  class="skuinfoitem"
                  v-for="(ctem, i) in item.productSkuName"
                  :key="i"
                >
                  {{ ctem }}
                </div>
              </div>
              <div class="flexbox">
                <div class="skuinfoprice">￥{{ item.unitPrice }}</div>
                <div class="skunum">x{{ item.num }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mainbox margintop20" @click="openCouponPup">
        <div class="flexbox">
          <div class="youhuilable">使用优惠券</div>
          <div>
            <span class="youhuilabletwo" v-if="chooseCouponData.preferential"
              >优惠金额：￥{{ chooseCouponData.preferential }}</span
            >
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div class="mainbox margintop20" @click="changeExpType">
        <div class="flexbox">
          <div class="youhuilable">运费支付</div>
          <div>
            <span class="youhuilabletwo" v-if="bookingInfo.expType == 1"
              >中通现付</span
            >
            <span class="youhuilabletwo" v-if="bookingInfo.expType == 2"
              >顺丰到付</span
            >
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div class="mainbox margintop20">
        <div class="flexbox hei60">
          <div class="youhuilable">商品合计</div>
          <div class="youhuival">￥{{ buyInfo.oldTotalAmount }}</div>
        </div>
        <div class="flexbox hei100">
          <div class="youhuilable">配送费用</div>
          <div class="youhuival">￥{{ buyInfo.freightAmount }}</div>
        </div>
        <div class="flexbox hei110">
          <div class="youhuilable">应付总额</div>
          <div class="youhuival">
            ￥{{ buyInfo.oldTotalAmount + buyInfo.freightAmount }}
          </div>
        </div>
      </div>
      <div class="mainbox margintop20" v-if="buyInfo.integral > 0">
        <div class="flexbox">
          <div class="youhuilable">
            <img
              class="jifenicon"
              src="../../assets/imgs/jifen@3x.png"
              alt=""
            />
            可用{{ buyInfo.integral }} 积分抵用{{ buyInfo.integral / 100 }} 元
          </div>
          <!-- <div class="uncheckjifenicon"></div> -->
          <div class="checkjifenicon" @click="changeJifen">
            <div v-show="isUseJifen" class="checkjifeniconmain"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="zhanweiforbuyfromcarttwo"></div>
    <div class="footboxbgz">
      <div
        class="footbox"
        :class="[buyInfo.deliverId ? 'footboxbgtwo' : 'footboxbgone']"
      >
        <div class="flexbox hei80">
          <div class="hei80">
            <span v-show="!isUseJifen && !isUseCoupon" class="footpriceone">
              ￥{{ buyInfo.totalAmount + buyInfo.freightAmount }}
            </span>
            <span v-show="isUseJifen || isUseCoupon" class="footpriceone">
              ￥{{ lastPrice }}
            </span>
            <span v-show="isUseJifen || isUseCoupon" class="footpricetwo"
              >￥{{ buyInfo.oldTotalAmount + buyInfo.freightAmount }}</span
            >
          </div>
          <div class="hei80">
            <div class="footpricelable" @click="createBooking">立即支付</div>
            <div class="footpriceicon">
              <img
                class="footpriceiconsize"
                src="../../assets/imgs/jiantou@3x.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showAddessPop"
      round
      safe-area-inset-bottom
      position="bottom"
      :style="{ height: '90%' }"
    >
      <div class="addresspop">
        <div class="zhanweiforaddress"></div>
        <div class="content">
          <div
            class="mainbox"
            v-for="item in addressList"
            :key="item.id"
            @click="chooseAddres(item)"
          >
            <div>
              <span class="usernamebox"> {{ item.name }} </span>
              <span class="usertelfont"> {{ item.tel }} </span>
            </div>
            <div class="addressinfobox">
              <div class="inlineblock" v-if="item.isDefault == 1">
                <div class="detailbox">默认</div>
              </div>
              <div class="inlineblock wid450">
                <span v-if="item.province">{{ item.province }}</span>
                <span v-if="item.city">{{ item.city }}</span>
                <span v-if="item.area">{{ item.area }}</span>
                <span v-if="item.address">{{ item.address }}</span>
              </div>
              <div class="editbox" @click.stop="openPup(item)">
                <img
                  class="editimg"
                  src="../../assets/imgs/bianji@3x.png"
                  alt=""
                />
              </div>
            </div>
            <div class="flexbox">
              <div class="flexbox wid180">
                <div class="checkboxbg" v-if="item.isDefault == 1">
                  <div class="checkbox"></div>
                </div>
                <div class="uncheckbox" v-if="item.isDefault == 0"></div>
                <div class="delfont">默认地址</div>
              </div>
              <div class="delfont" @click.stop="delAddress(item.id)">删除</div>
            </div>
          </div>
        </div>
        <div class="zhanweiaddress"></div>
        <div class="footboxbg">
          <div class="footbox" @click="openPup">添加收货地址</div>
        </div>
        <van-overlay :show="showOverlay">
          <div>
            <div class="zhanweiforover"></div>
            <div class="hei80vh">
              <div class="overicon">
                <van-icon
                  name="cross"
                  @click.stop="closeOverlay"
                  color="#333333"
                  size="30"
                />
              </div>

              <van-address-edit
                :area-list="areaList"
                show-postal
                show-delete
                show-set-default
                show-search-result
                :address-info="addressInfo"
                :search-result="searchResult"
                :area-columns-placeholder="['请选择', '请选择', '请选择']"
                @save="onSave"
                @delete="onDelete"
                @change-detail="onChangeDetail"
              />
            </div>
          </div>
        </van-overlay>
      </div>
    </van-popup>

    <van-popup
      v-model="showCouponPop"
      round
      safe-area-inset-bottom
      position="bottom"
      :style="{ height: '60%', background: '#eeeee2' }"
    >
      <div class="couponbox">
        <div class="content">
          <div
            class="mainitembox"
            v-for="item in buyInfo.couponList"
            :key="item.id"
            @click="chooseCoupon(item)"
          >
            <div class="canuseleftbox">
              <div class="padtop50" v-if="item.type == 'CASH'">
                <span class="canlefticon">￥</span>
                <span class="canleftnum">{{ item.benefitCash }}</span>
              </div>
              <div class="padtop50" v-if="item.type == 'DISCOUNT'">
                <span class="canleftnumt"
                  >{{ item.benefitDiscount * 10 }}折券</span
                >
              </div>
              <div class="canleftlable" v-if="item.conditionAmount > 0">
                满{{ item.conditionAmount }}元使用
              </div>
              <div class="canleftlable" v-else>无门槛优惠券</div>
            </div>
            <div class="canrightbox">
              <div class="canrightinfo">
                <div class="canrightvale">
                  <span v-if="item.type == 'CASH'">【现金券】</span>
                  <span v-if="item.type == 'DISCOUNT'">【折扣券】</span
                  >{{ item.name }}
                </div>
                <div class="canrightlable">
                  {{ item.memo }} 有效期 {{ item.startDate | formartDate }}至{{
                    item.endDate | formartDate
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="mainitembox" @click="chooseCoupon('cancel')">
            <van-button class="wid717" type="default">不使用优惠券</van-button>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showPayTypePop"
      round
      closeable
      close-icon-position="top-left"
      safe-area-inset-bottom
      position="bottom"
      :style="{ height: '55%' }"
    >
      <div class="paytypebox">
        <div class="zhanweiforpaytypepop"></div>
        <!-- <div class="paypeicebox">￥{{ orderInfo.paidAmount }}</div> -->
        <div class="paypeicebox">
          ￥
          <span v-if="!isUseJifen && !isUseCoupon">
            {{ buyInfo.totalAmount + buyInfo.freightAmount }}
          </span>
          <span v-if="isUseJifen || isUseCoupon">
            {{ lastPrice }}
          </span>
        </div>
        <div class="paylable">支付方式</div>
        <div class="paymainbox">
          <div class="flexbox">
            <div>
              <div>
                <div class="inlineblock">
                  <img
                    class="payicon"
                    src="../../assets/imgs/zfb@3x.png"
                    alt=""
                  />
                </div>
                <div class="inlineblock marginleft32">支付宝支付</div>
              </div>
            </div>
            <div class="checkjifenicon" @click="changgePayType('alipay')">
              <div v-if="payType == 'alipay'" class="checkjifeniconmain"></div>
            </div>
          </div>
        </div>
        <!-- <div class="paymainbox">
          <div class="flexbox">
            <div>
              <div>
                <div class="inlineblock">
                  <img
                    class="payicon"
                    src="../../assets/imgs/wx@3x.png"
                    alt=""
                  />
                </div>
                <div class="inlineblock marginleft32">微信支付</div>
              </div>
            </div>
            <div class="checkjifenicon" @click="changgePayType('wxpay')">
              <div v-if="payType == 'wxpay'" class="checkjifeniconmain"></div>
            </div>
          </div>
        </div> -->
        
        <div class="paybtnbox" @click="payforOrder">确认付款</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import {
  buyInfoOne_api,
  buyInfoTwo_api,
  freightAmount_api,
  createBooking_api,
  testPay_pai,
  payOrderforAli_api,
  payOrderforWXW_api,
} from "../../apis/order";
import {
  userInfo_api,
  addressList_api,
  addressSave_api,
  delAddress_api,
} from "../../apis/mine";
import Logodia from "../../components/Logodia.vue";
export default {
  components: {
    Logodia,
  },
  data() {
    return {
      isUseJifen: false,
      isUseCoupon: false,
      isAddress: true,
      adddressInfo: {
        name: "爱谁谁",
        tel: "13404008408",
        address: "墨脱",
      },
      detailSearch: {
        id: "",
        num: "",
        skuids: "",
        deliverId: "",
      },
      buyInfo: {
        couponList: [],
        deliverAddress: "",
        deliverArea: "",
        deliverCity: "",
        deliverId: "",
        deliverName: "",
        deliverProvince: "",
        deliverTel: "",
        freightAmount: 0,
        integral: 0,
        integralAmount: 0,
        num: 0,
        shoppingCartId: "",
        totalAmount: 0,
        productList: [],
      },
      userInfo: "",

      showAddessPop: false,
      showCouponPop: false,

      areaList: areaList,
      isDetail: true,
      showOverlay: false,
      searchResult: [],
      addressInfo: {
        county: "",
        addressDetail: "",
        areaCode: "",
        postalCode: "",
        isDefault: true,

        address: "",
        area: "",
        city: "",
        province: "",
        name: "",
        //  isDefault: 1,
        tel: "",
      },
      areaCode: "",
      addressList: [],
      lastPrice: "",
      chooseCouponData: "",
      spids: "",

      bookingInfo: {
        couponId: "",
        deliverId: "",
        isintegral: "",
        preferential: "",
        productIds: [],
        shoppingCartId: "",
        productList: [],
        expType: 1,
      },
      orderId: "",
      orderInfo: "",
      showPayTypePop: false,
      payType: "alipay",
      freightAmount: 0,
    };
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query.type) {
      
      if (this.$route.query.type == "detail") {
        this.detailSearch.id = this.$route.query.id;
        this.detailSearch.num = this.$route.query.num;
        this.detailSearch.skuids = this.$route.query.skuids;
        this.getBuyInfoOne();
      } else if (this.$route.query.type == "cart") {
        this.spids = this.$route.query.spids;
        this.bookingInfo.shoppingCartId = this.$route.query.spids;
        this.getBuyInfoTwo();
      }
    }
    this.getUserInfo();
    this.getAddressList();
  },
  filters: {
    formartDate(val) {
      if (val) {
        let newVal = val.substring(0, 10);
        return newVal;
      }
    },
  },
  // computed: {
  //   allPrice() {
  //     return (
  //       this.buyInfo.totalAmount +
  //       this.buyInfo.freightAmount -
  //       this.userInfo.integral / 100
  //     );
  //   },
  // },
  mounted() {
    document.addEventListener("visibilitychange", function (e) {
      console.log("visibilitychange", e);
      this.$router.push(
        {
          path: "/orderlist",
        },
        () => {}
      );
    });
  },
  methods: {
    async getBuyInfoOne() {
      let { code, data } = await buyInfoOne_api(this.detailSearch);
      if (code == 0) {
        this.buyInfo = data;
        this.$set(this.buyInfo, "oldTotalAmount", this.buyInfo.totalAmount);
        this.bookingInfo.productList = this.buyInfo.productList;
        this.bookingInfo.deliverId = this.buyInfo.deliverId;
        let newData = JSON.parse(JSON.stringify(this.buyInfo));
        this.freightAmount = newData.freightAmount;

      }
    },
    async getBuyInfoTwo() {
      let { code, data } = await buyInfoTwo_api({
        spids: this.spids,
      });
      if (code == 0) {
        this.buyInfo = data;
        this.$set(this.buyInfo, "oldTotalAmount", this.buyInfo.totalAmount);
        this.bookingInfo.productList = this.buyInfo.productList;
        this.bookingInfo.deliverId = this.buyInfo.deliverId;
        let newData = JSON.parse(JSON.stringify(this.buyInfo));
        this.freightAmount = newData.freightAmount;
      }
    },
    changeJifen() {
      this.isUseJifen = !this.isUseJifen;
      if (this.isUseJifen) {
        this.bookingInfo.isintegral = 1;
      } else {
        this.bookingInfo.isintegral = 0;
      }
      this.getLastPrice();
    },
    openCouponPup() {
      if (this.buyInfo.couponList && this.buyInfo.couponList.length > 0) {
        this.showCouponPop = true;
        this.isUseCoupon = false;
      } else {
        this.$dialog.alert({
          message: "暂无可用优惠券",
        });
      }
    },
    async getUserInfo() {
      let { code, data } = await userInfo_api();
      if (code == 0) {
        this.userInfo = data;
      }
    },
    openAddressPop() {
      this.showAddessPop = true;
    },

    openPup(item) {
      if (item && item.id) {
        this.addressInfo = item;
        this.addressInfo.county = this.addressInfo.area;
        this.addressInfo.addressDetail = this.addressInfo.address;
        if (this.addressInfo.isDefault == 1) {
          this.addressInfo.isDefault = true;
        } else if (this.addressInfo.isDefault == 0) {
          this.addressInfo.isDefault = false;
        }
      } else {
        this.addressInfo = {
          county: "",
          addressDetail: "",
          areaCode: "",
          postalCode: "",
          isDefault: true,

          address: "",
          area: "",
          city: "",
          province: "",
          name: "",

          tel: "",
        };
      }

      this.init();

      this.showOverlay = true;
    },
    async init() {
      console.log("init");
      _.forEach(this.areaList.city_list, (o, c) => {
        if (o == this.addressInfo.city) {
          let cityId = String(_.take(c, 2));
          _.forEach(this.areaList.county_list, (i, a) => {
            if (i == this.addressInfo.county) {
              let districtId = String(_.take(a, 2));
              if (cityId == districtId) {
                this.addressInfo.areaCode = a;
              }
            }
          });
        }
      });
      console.log(this.addressInfo);
    },
    async onSave(val) {
      console.log(val);
      this.addressInfo = Object.assign(this.addressInfo, val);
      this.addressInfo.area = this.addressInfo.county;
      this.addressInfo.address = this.addressInfo.addressDetail;

      if (this.addressInfo.isDefault == true) {
        this.addressInfo.isDefault = 1;
      } else if (this.addressInfo.isDefault == false) {
        this.addressInfo.isDefault = 0;
      }

      let { code, data } = await addressSave_api(this.addressInfo);
      if (code == 0) {
        this.showOverlay = false;
        this.getAddressList();
      }
    },
    onDelete(val) {
      console.log(val);
      if (this.addressInfo.id) {
        this.delAddressFn(this.addressInfo.id);
      } else {
        this.addressInfo = {
          county: "",
          addressDetail: "",
          areaCode: "",
          postalCode: "",
          isDefault: true,

          address: "",
          area: "",
          city: "",
          province: "",
          name: "",
          //  isDefault: 1,
          tel: "",
        };
      }
      this.showOverlay = false;
    },
    delAddress(id) {
      this.$dialog
        .confirm({
          message: "确定要删除吗？",
        })
        .then(() => {
          this.delAddressFn(id);
        });
    },
    async delAddressFn(id) {
      let { code, data } = await delAddress_api({
        id: id,
      });
      if (code == 0) {
        this.getAddressList();
      }
    },
    onChangeDetail(val) {
      console.log(val);
    },
    closeOverlay() {
      this.showOverlay = false;
    },
    async getAddressList() {
      let { code, data } = await addressList_api();
      if (code == 0) {
        this.addressList = data;
      }
    },
    chooseAddres(item) {
      console.log(item);
      this.buyInfo.deliverProvince = item.province;
      this.buyInfo.deliverCity = item.city;
      this.buyInfo.deliverArea = item.area;
      this.buyInfo.deliverAddress = item.address;
      this.buyInfo.deliverName = item.name;
      this.buyInfo.deliverTel = item.tel;
      this.buyInfo.deliverId = item.id;
      this.bookingInfo.deliverId = item.id;
      this.getFreightAmount();
      this.showAddessPop = false;
    },
    async getFreightAmount() {
      let { code, data } = await freightAmount_api({
        deliverId: this.buyInfo.deliverId,
        productList: this.buyInfo.productList,
      });
      if (code == 0) {
        this.buyInfo.freightAmount = data;
        let newData = JSON.parse(JSON.stringify(this.buyInfo));
        this.freightAmount = newData.freightAmount;
        // this.freightAmount = JObject.assign({}, this.buyInfo.freightAmount)
        console.log(this.freightAmount, " this.freightAmount");

        this.bookingInfo.expType = 1;
      }
    },
    chooseCoupon(item) {
      if (item == "cancel") {
        this.isUseCoupon = false;
        this.chooseCouponData = "";
      } else {
        this.isUseCoupon = true;
        this.chooseCouponData = item;
      }

      this.showCouponPop = false;
      console.log(item);
      // if (item.type == "DISCOUNT") {
      //   console.log(this.buyInfo.totalAmount);
      //   this.buyInfo.totalAmount =
      //     this.buyInfo.oldTotalAmount - item.benefitCash;
      //   console.log(this.buyInfo.totalAmount);
      // } else if (item.type == "CASH") {
      // }
      this.getLastPrice();
    },
    getLastPrice() {
      this.bookingInfo.couponId = "";
      this.bookingInfo.productIds = [];
      if (!this.isUseCoupon && !this.isUseJifen) {
        this.lastPrice =
          this.buyInfo.oldTotalAmount + this.buyInfo.freightAmount;
      } else if (!this.isUseCoupon && this.isUseJifen) {
        this.lastPrice =
          this.buyInfo.oldTotalAmount +
          this.buyInfo.freightAmount -
          this.buyInfo.integral / 100;
      } else if (this.isUseCoupon && !this.isUseJifen) {
        this.bookingInfo.couponId = this.chooseCouponData.id;
        // if (this.chooseCouponData.type == "CASH") {
        //   this.lastPrice =
        //     this.buyInfo.oldTotalAmount +
        //     this.buyInfo.freightAmount -
        //     this.chooseCouponData.benefitCash;
        // } else if (this.chooseCouponData.type == "DISCOUNT") {
        //   if (this.chooseCouponData.productIds.length == 0) {
        //     this.lastPrice =
        //       this.buyInfo.oldTotalAmount *
        //         this.chooseCouponData.benefitDiscount +
        //       this.buyInfo.freightAmount;
        //   } else {
        //     let newAllpriceOne = 0;
        //     this.buyInfo.productList.forEach((item) => {
        //       this.chooseCouponData.productIds.forEach((ctem) => {
        //         if (item.productId == ctem) {
        //           item.paidAmount =
        //             item.paidAmount * this.chooseCouponData.benefitDiscount;
        //         }
        //       });
        //       newAllpriceOne = newAllpriceOne + item.paidAmount;
        //     });
        //     this.lastPrice = newAllpriceOne + this.buyInfo.freightAmount;
        //     this.bookingInfo.productIds = this.chooseCouponData.productIds;
        //   }
        // }

        //  if (this.chooseCouponData.type == "CASH") {
        //   this.lastPrice =
        //     this.buyInfo.oldTotalAmount +
        //     this.buyInfo.freightAmount -
        //     this.chooseCouponData.benefitCash;
        // } else if (this.chooseCouponData.type == "DISCOUNT") {}

        this.lastPrice =
          this.buyInfo.oldTotalAmount +
          this.buyInfo.freightAmount -
          this.chooseCouponData.preferential;
      } else if (this.isUseCoupon && this.isUseJifen) {
        this.bookingInfo.couponId = this.chooseCouponData.id;
        this.lastPrice =
          this.buyInfo.oldTotalAmount +
          this.buyInfo.freightAmount -
          this.buyInfo.integral / 100 -
          this.chooseCouponData.preferential;

        // if (this.chooseCouponData.type == "CASH") {
        //   this.lastPrice =
        //     this.buyInfo.oldTotalAmount +
        //     this.buyInfo.freightAmount -
        //     this.buyInfo.integral / 100 -
        //     this.chooseCouponData.benefitCash;
        // } else if (this.chooseCouponData.type == "DISCOUNT") {
        //   if (this.chooseCouponData.productIds.length == 0) {
        //     this.lastPrice =
        //       this.buyInfo.oldTotalAmount *
        //         this.chooseCouponData.benefitDiscount +
        //       this.buyInfo.freightAmount -
        //       this.buyInfo.integral / 100;
        //   } else {
        //     let newAllpriceOne = 0;
        //     this.buyInfo.productList.forEach((item) => {
        //       this.chooseCouponData.productIds.forEach((ctem) => {
        //         if (item.productId == ctem) {
        //           item.paidAmount =
        //             item.paidAmount * this.chooseCouponData.benefitDiscount;
        //         }
        //       });
        //       newAllpriceOne = newAllpriceOne + item.paidAmount;
        //     });
        //     this.lastPrice =
        //       newAllpriceOne +
        //       this.buyInfo.freightAmount -
        //       this.buyInfo.integral / 100;
        //     this.bookingInfo.productIds = this.chooseCouponData.productIds;
        //   }
        // }
      }
      this.lastPrice = this.lastPrice.toFixed(2);
    },
    // async createBooking() {
    //   if (!this.buyInfo.deliverId) {
    //     this.$toast("请选择收货地址");
    //   } else {
    //     let { code, data } = await createBooking_api(this.bookingInfo);
    //     if (code == 0) {
    //       this.orderInfo = data;

    //       this.showPayTypePop = true;
    //     }
    //   }
    // },
    createBooking() {
      if (!this.buyInfo.deliverId) {
        this.$toast("请选择收货地址");
      } else {
        this.showPayTypePop = true;
      }
    },
    async testPay() {
      let { code, data } = await testPay_pai({
        id: this.orderInfo.id,
      });
      if (code == 0) {
        this.$toast("购买成功");
      }
    },
    changgePayType(type) {
      this.payType = type;
    },
    //判断是否微信
    is_weixn() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },

    async payforOrder() {
      let { code, data } = await createBooking_api(this.bookingInfo);
      if (code == 0) {
        this.orderInfo = data;
        if (this.is_weixn()) {
          if (this.payType == "alipay") {
            this.$toast("请右上角浏览器打开内支付~");
          } else if (this.payType == "wxpay") {
            this.$toast("请右上角浏览器打开内支付~");
          }
        } else {
          if (this.payType == "alipay") {
            this.$router.push({
              path: "/aliPay",
              query: { orderId: this.orderInfo.id },
            });
          } else if (this.payType == "wxpay") {
            // this.$toast("功能尚在开发中~");
            this.weixinPayweb();
          }
        }
      }
    },
    async weixinPayweb() {
      let { code, data, message } = await payOrderforWXW_api({
        id: this.orderInfo.id,
      });
      if (code == 0) {
        console.log("data", data);
        console.log("message", message);
        window.location.replace(message);
      }
    },
    async payOrderforAli() {
      let { code, data, message } = await payOrderforAli_api({
        id: this.orderInfo.id,
      });
      if (code == 0) {
        console.log(message);
        const div = document.createElement("div");
        div.innerHTML = message; //res.data是返回的表单
        document.body.appendChild(div);
        document.forms.alipaysubmit.submit();
      }
    },
    changeExpType() {
      console.log(this.freightAmount, "this.freightAmount");
      if (this.bookingInfo.expType == 1) {
        this.bookingInfo.expType = 2;
        this.buyInfo.freightAmount = 0;
      } else if (this.bookingInfo.expType == 2) {
        this.bookingInfo.expType = 1;
        this.buyInfo.freightAmount = this.freightAmount;
      }
      this.getLastPrice();
    },
  },
};
</script>

<style lang="less" scoped>
.zhanweiforbuyfromcart {
  width: 100%;
  height: 70px;
}
.contentbox {
  margin: 0 auto;
  width: 690px;
}
.mainbox {
  padding: 40px 30px;
  width: 630px;
  background: #fff;
}
.addressbox {
  min-height: 100px;
  width: 100%;
  border-bottom: 1px dashed #c4c4c4;
  position: relative;
}
.inlineblock {
  display: inline-block;
  vertical-align: top;
}
.jiaicon {
  width: 26px;
  height: 26px;
  position: relative;
  top: 32px;
}
.marginleft13 {
  margin-left: 13px;
  font-size: 26px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  line-height: 100px;
}
.margintop20 {
  margin-top: 20px;
}
.goodstitle {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}
.titlelable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}
.titleval {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #999999;
  letter-spacing: 5px;
}
.mainitembox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.mainimg {
  width: 180px;
  height: 180px;
  object-fit: cover;
}
.mainiteninfobox {
  width: 420px;
  height: 180px;
}
.infonamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}
.infotitlebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}
.skuinfo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}
.skuinfoitem {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.skuinfoprice {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}
.flexboxtwo {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.skunum {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3px;
}
.youhuilable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}
.youhuilabletwo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #e63c3d;
}
.youhuival {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 400;
  color: #000000;
}
.hei60 {
  height: 60px;
}
.hei100 {
  height: 80px;
  border-bottom: 1px dashed #c4c4c4;
}
.hei110 {
  height: 60px;
}
.jifenicon {
  display: inline-block;
  width: 29px;
  height: 29px;
  margin-right: 5px;
  position: relative;
  top: 5px;
}
.uncheckjifenicon {
  width: 28px;
  height: 28px;
  background: #ffffff;
  border: 2px solid #999999;
  border-radius: 50%;
}
.checkjifenicon {
  width: 28px;
  height: 28px;
  background: #ffffff;
  border: 2px solid #986665;
  border-radius: 50%;
}
.checkjifeniconmain {
  width: 20px;
  height: 20px;
  background: #986665;
  border-radius: 50%;
  margin: 4px;
}
.zhanweiforbuyfromcarttwo {
  width: 100%;
  height: 200px;
}
.footboxbgz {
  width: 750px;
  height: 127px;
  padding-top: 33px;
  background: #fff;
  position: fixed;
  left: 0px;
  bottom: 0px;
}
.footbox {
  width: 630px;
  padding: 0 30px;
  margin: 0 auto;
  color: #fff;
}
.footboxbgone {
  background: #cccccc;
}
.footboxbgtwo {
  background: #986665;
}
.footpriceone {
  font-size: 28px;
  font-family: Heiti SC;
  font-weight: 300;
}
.footpricetwo {
  font-size: 20px;
  font-family: Heiti SC;
  font-weight: 300;
  color: #e5e5e5;
  margin-left: 10px;
  text-decoration: line-through;
}
.footpricelable {
  font-size: 26px;
  font-family: PingFang;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
.footpriceicon {
  display: inline-block;
  height: 80px;
}
.footpriceiconsize {
  margin-top: 22px;
  width: 36px;
  height: 36px;
}
.hei80 {
  height: 80px;
  line-height: 80px;
}
.addressinfobox {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}
.addressnamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
  margin-bottom: 10px;
}
.marginleft20 {
  margin-left: 20px;
}
.addresspop {
  .zhanweiforaddress {
    width: 100%;
    height: 70px;
  }
  .content {
    width: 690px;
    margin: 0 auto;
  }
  .mainbox {
    width: 630px;
    padding: 30px 30px 40px 30px;
    background: #ffffff;
    border: 1px solid #dddddd;
    margin-bottom: 20px;
  }
  .usernamebox {
    font-size: 30px;
    font-family: PingFang;
    font-weight: bold;
    color: #333333;
  }
  .usertelfont {
    font-size: 24px;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    margin-left: 20px;
  }
  .addressinfobox {
    margin-top: 20px;
    position: relative;
    height: 90px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 23px;
  }
  .inlineblock {
    display: inline-block;
    vertical-align: top;
  }
  .detailbox {
    width: 56px;
    height: 26px;
    background: #ffeee7;
    border-radius: 4px 0px 4px 0px;

    line-height: 26px;
    text-align: center;
    font-size: 20px;
    font-family: PingFang;
    font-weight: 500;
    color: #d62929;
    margin-right: 20px;
  }
  .wid450 {
    width: 450px;
    font-size: 26px;
    font-family: PingFang;
    font-weight: 500;
    color: #333333;
  }
  .editbox {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .editimg {
    width: 30px;
    height: 30px;
  }
  .flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .checkboxbg {
    width: 28px;
    height: 28px;
    background: #ffffff;
    border: 2px solid #986665;
    border-radius: 50%;
  }
  .delfont {
    font-size: 24px;
    font-family: PingFang;
    font-weight: 500;
    color: #666666;
  }
  .wid180 {
    width: 150px;
  }
  .checkbox {
    width: 20px;
    height: 20px;
    background: #986665;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 4px;
  }
  .uncheckbox {
    width: 26px;
    height: 26px;
    border: 1px solid #999999;
    border-radius: 50%;
  }
  .footboxbg {
    width: 750px;
    height: 130px;
    padding-top: 20px;
    position: fixed;
    left: 0px;
    bottom: 0px;
    background: #eeeee2;
  }
  .footbox {
    margin: 0 auto;
    width: 690px;
    height: 80px;
    background: #986665;
    line-height: 80px;
    text-align: center;

    font-size: 26px;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
  }
  .zhanweiforover {
    width: 100%;
    height: 20vh;
  }
  .hei80vh {
    height: 80vh;
    background-color: #fff;
  }
  .overicon {
    margin-left: 30px;
    padding-top: 30px;
  }
  .zhanweiaddress {
    width: 100%;
    height: 150px;
  }
}
.couponbox {
  background: #eeeee2;
  .content {
    width: 690px;
    margin: 0 auto;
  }
  .mainitembox {
    margin-top: 20px;
    border-radius: 15px;
    background: #fff;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
  }
  .canuseleftbox {
    display: inline-block;
    vertical-align: top;
    width: 188px;
    height: 188px;
    background: #986665;
    border-radius: 15px 0px 0px 15px;
  }
  .padtop50 {
    padding-top: 30px;
    text-align: center;
  }
  .canlefticon {
    // margin-left: 34px;
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
  }
  .canleftnum {
    font-size: 60px;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
  }
  .canleftnumt {
    font-size: 48px;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
  }
  .canleftlable {
    text-align: center;
    font-size: 24px;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
  }
  .canrightbox {
    width: 502px;
    height: 188px;
    background: #ffffff;
    border-radius: 0px 15px 15px 0px;
    display: inline-block;
    vertical-align: top;
  }
  .wid717 {
    width: 717px;
  }
  .canrightinfo {
    margin-left: 30px;
    width: 470px;
    height: 188px;
    // background: #4d36ff;
    display: inline-block;
    vertical-align: top;
  }
  .canrightvale {
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #333333;
    padding-top: 30px;
    margin-bottom: 20px;
  }
  .canrightlable {
    font-size: 20px;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
  }
  .canrightbtn {
    width: 112px;
    height: 44px;
    background: #986665;
    border-radius: 22px;
    display: inline-block;

    line-height: 44px;
    text-align: center;
    font-size: 20px;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
    position: relative;
    top: 72px;
  }
  .nocanrightbtn {
    text-align: center;
  }
  .canuserightbox {
    display: inline-block;
    vertical-align: top;
    width: 188px;
    height: 188px;
    background: #e4e4e4;
    border-radius: 15px 0px 0px 15px;
  }
  .canrighticon {
    margin-left: 34px;
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #c9c9c9;
  }
  .canleftnumtwo {
    font-size: 60px;
    font-family: PingFang;
    font-weight: bold;
    color: #c9c9c9;
  }
  .canleftnumtwot {
    font-size: 48px;
    font-family: PingFang;
    font-weight: bold;
    color: #c9c9c9;
  }
}
.paytypebox {
  .zhanweiforpaytypepop {
    width: 100%;
    height: 88px;
  }
  .paypeicebox {
    text-align: center;
    font-size: 36px;
    font-family: Heiti;
    font-weight: 500;
    color: #333333;
  }
  .paylable {
    margin-left: 30px;
    font-size: 24px;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    margin-top: 32px;
    margin-bottom: 52px;
  }
  .paymainbox {
    margin-left: 30px;
    width: 663px;
    padding-right: 7px;
    padding-bottom: 45px;
    border-bottom: 1px solid #eeeeee;
    margin-top: 43px;

    font-size: 30px;
    font-family: PingFang;
    font-weight: bold;
    color: #333333;
  }
  .payicon {
    width: 38px;
    height: 38px;
  }
  .marginleft32 {
    margin-left: 32px;
  }
  .paybtnbox {
    margin: 0 auto;
    margin-top: 60px;
    width: 690px;
    height: 80px;
    background: #986665;
    text-align: center;
    line-height: 80px;
    font-size: 26px;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>