<template>
  <div>
    <!-- <Categorylist></Categorylist> -->
    <div>
      <div class="mainbox">
        <div class="maintitle">
          {{ bulletinBoardInfo.title }}
        </div>
        <div class="mainlable">
          <span>{{ bulletinBoardInfo.author || "seooocookie" }}</span>
          <span class="marginleft20">{{ bulletinBoardInfo.createTime }}</span>
          <span class="marginleft20"
            >查看次数{{ bulletinBoardInfo.scan }}次</span
          >
        </div>
        <div class="maincontent">
          <p v v-html="bulletinBoardInfo.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Categorylist from "../../components/Categorylist.vue";
import { bulletinBoardInfo_api } from "../../apis/bulletinboard";
export default {
  components: {
    Categorylist,
  },
  data() {
    return {
      bulletinBoardInfo: {
        title: "",
        author: "",
        content: "",
        isactive: "",
        scan: 0,
        sortnumber: 1,
        title: "",
      },

      bulletinBoardId: "",
    };
  },
  created() {
    this.bulletinBoardId = this.$route.query.id;
    this.getBulletinBoardInfo();
  },
  methods: {
    async getBulletinBoardInfo() {
      let { code, data } = await bulletinBoardInfo_api({
        id: this.bulletinBoardId,
      });
      if (code == 0) {
        this.bulletinBoardInfo = data;
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.mainbox {
  margin: 0 auto;
  margin-top: 58px;
  width: 690px;
  text-align: center;
}
.maintitle {
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}
.mainlable {
  margin-top: 20px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}
.marginleft20 {
  margin-left: 20px;
}
.maincontent {
  margin-top: 58px;
  padding-bottom: 100px;
}
</style>