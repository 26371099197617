<template>
  <div>
    <!-- <Categorylist></Categorylist> -->
    <div>
      <div class="titlez">{{ bulletinBoardTitle }}</div>
      <div
        class="mainbox"
        v-for="item in bulletinBoardList"
        :key="item.id"
        @click="toBulletinBoardIfo(item)"
      >
        <div class="maintitle">
          {{ item.title }}
        </div>
        <div class="mainlable">
          <span>{{ item.author || "seooocookie" }}</span>
          <span class="marginleft20">{{ item.createTime }}</span>
          <span class="marginleft20">查看次数{{ item.scan }}次</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Categorylist from "../../components/Categorylist.vue";
import { bulletinBoardList_api } from "../../apis/bulletinboard";
export default {
  components: {
    Categorylist,
  },
  data() {
    return {
      bulletinBoardList: [],
      searchData: {
        pageNumber: 1,
        pageSize: 10,
      },
      bulletinBoardTitle: "<公告>",
    };
  },
  created() {
    this.getBulletinBoardList();
  },
  methods: {
    async getBulletinBoardList() {
      let { code, data } = await bulletinBoardList_api(this.searchData);
      if (code == 0) {
        this.bulletinBoardList = data.rows;
        this.total = data.total;
      }
    },
    toBulletinBoardIfo(item) {
      this.$router.push(
        {
          path: "/bulletinboardinfo",
          query: {
            id: item.id,
          },
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="less" scoped>
.titlez {
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}
.mainbox {
  margin: 0 auto;
  margin-top: 58px;
  width: 690px;
  padding-bottom: 10px;
  border-bottom: 2px solid #111;
}
.maintitle {
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}
.mainlable {
  margin-top: 20px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}
.marginleft20 {
  margin-left: 20px;
}
</style>